
import { onUnmounted, ref, watchEffect } from "vue";
import { useAccount } from "../hooks/useAccount";
import Lessons from "../components/Lessons.vue";

export default {
  props: ["account"],
  components: {
    Lessons
  },
  setup(props) {
    const user = ref("");
    const { account: sender, unsubscribe } = useAccount();
    onUnmounted(() => {
      unsubscribe();
    });

    watchEffect(() => {
      if (props.account) {
        user.value = props.account;
      } else {
        user.value = sender.value;
      }
    });

    return {
      user
    };
  },
  data() {
    return {
      isAuth: false
    };
  }
};
