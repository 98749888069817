
import AccountManager from "../accountManagerUi";
import robonomics from "../robonomics";
import Account from "./Account.vue";
import Languages from "./Languages.vue";

export default {
  emits: ["login", "logout"],
  components: { Account, Languages },
  data() {
    return {
      isReady: false,
      isAuth: false,
      type: "user"
    };
  },
  async created() {
    await robonomics.run();
    await AccountManager.initPlugin(robonomics.accountManager.keyring, {
      isDevelopment: false
    });
    this.isReady = true;
  }
};
