
import robonomics from "../robonomics";
import { encodeAddress } from "@polkadot/util-crypto";
import { auth, logout } from "../api";

export default {
  emits: ["login", "logout", "type"],
  data() {
    return {
      account: null,
      accounts: [],
      type: "user",
      isAuth: false
    };
  },
  async created() {
    robonomics.accountManager.onReady(() => {
      this.accounts = robonomics.accountManager.getAccounts();
      if (this.accounts.length) {
        this.account = this.accounts[0].address;
      }
    });
  },
  watch: {
    async account(address) {
      this.isAuth = false;
      logout();
      await robonomics.accountManager.selectAccountByAddress(address);
    },
    isAuth(v) {
      if (v) {
        this.$emit("login");
      } else {
        this.$emit("logout");
      }
    },
    type(v) {
      this.$emit("type", v);
    }
  },
  methods: {
    async auth() {
      this.isAuth = false;
      const address = encodeAddress(
        robonomics.accountManager.account.address,
        32
      );
      const signature = await robonomics.accountManager.account.signMsg(
        address
      );
      const res = await auth(address, signature);
      if (res.isValid) {
        this.isAuth = true;
        this.type = res.type;
      }
    }
  }
};
