
import { get } from "../api";

export default {
  data() {
    return {
      users: []
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      const result = await get("/users");
      if (result && result.users) {
        this.users = result.users;
      }
    }
  }
};
