
import { get } from "../api";

export default {
  props: ["account"],
  data() {
    return {
      lesson1: [],
      lesson2: { list: [], count: 0 },
      lesson3: [],
      lesson4: { list: [], count: 0 },
      lesson5: null,
      viewLesson2Index: null,
      viewLesson4Index: null
    };
  },
  created() {
    this.getLessons();
  },
  computed: {
    link() {
      return (block, index) => {
        return `https://robonomics.subscan.io/extrinsic/${block}-${index}`;
      };
    },
    date() {
      return (v) => {
        return new Date(Number(v)).toLocaleDateString();
      };
    },
    passed() {
      return (lessons, require) => {
        for (const lesson of lessons) {
          if (lesson.corrects >= require) {
            return true;
          }
        }
        return false;
      };
    },
    launch() {
      return (v) => {
        return v ===
          "0x0000000000000000000000000000000000000000000000000000000000000001"
          ? "on"
          : "off";
      };
    },
    isBonus() {
      if (
        this.lesson2.list.length > 0 &&
        this.lesson4.list.length > 0 &&
        this.lesson2.list[this.lesson2.list.length - 1].corrects >= 15 &&
        this.lesson4.list[this.lesson4.list.length - 1].corrects >= 10
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    async getLessons() {
      const result = await get(`/user/${this.account}`);
      if (result && result.lesson1) {
        this.lesson1 = result.lesson1;
        this.lesson2 = result.lesson2;
        this.lesson3 = result.lesson3;
        this.lesson4 = result.lesson4;
        this.lesson5 = result.lesson5;
        this.viewLesson2Index = this.lesson2.count - 1;
        this.viewLesson4Index = this.lesson4.count - 1;
      }
    }
  }
};
