
export default {
  data() {
    return {
      locale: this.$i18n.locale
    };
  },
  watch: {
    locale(newValue) {
      this.$i18n.locale = newValue;
      localStorage.setItem("locale", newValue);
    }
  }
};
